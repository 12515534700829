.w-100{
    width: 100%;
}
.w-75{
    width: 75%;
}
.w-50{
    width: 50%;
}
.w-25{
    width: 25%;
}
.center{
    margin: 0 auto;
}
.red{
    background-color: #ccdde8;
}
// .app__works {
//     flex:1;
//     width: 100%;
//     flex-direction: column;
// }
// .app__works-filter {
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;
//     flex-wrap: wrap;
//     margin: 4rem 0 2rem;

//     .app__works-filter-item {
//         padding: 0.5rem 1rem;;
//         border-radius: 0.5rem;
//         background-color: #fff;
//     }
// }
.app__work_portfolio{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;

    .app__work-item{
        width:270px;
        flex-direction: column;

        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #a9a9a9;
        color: black;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover{
            box-shadow: 0 0 25px rgba(0,0,0,0.2);
        }

        @media screen and (min-width:2000px) {
            width: 470px;
            padding: 1.25rem;
            
        }
        @media screen and (max-width:300px) {
            width: 100%;
           margin: 1rem;
            
        }
    }
}
.app__work-img{
    width:100%;
    height: 230px;

    position: relative;

    img{
        width:100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }
    @media screen and (min-width:2000px) {
        height: 300px;
        
    }
}
.app__Work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height:100%;
    
    background-color: rgba(0,0,0,0.5);

    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.5);
        color: #fff;

        margin:1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        svg{
            width: 50%;
            height: 50%;
            color: rgb(81, 219, 22);
        }
    }
}

.app__work-content{
    padding: 0.5rem;
    width: 100%;
    position: relative; 
    flex-direction: column;

    h4{
        margin-top: 1rem;
        line-height: 1.5;
    }

    .app__work-tag{
        position: absolute;

        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #fff;
        top: -25px
    }
}