.app__skills-body{
    background-color: #f0ebf5;
 
}
.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;

}
.gray{
    background-color:gray;
}

.app__skills-container {
    width: 80%;
    margin-top: 3rem;
 
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        width: 100%;
        flex-direction: column;
    }
}

.app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    margin-right: 5rem;

    @media screen and (max-width: 900px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;
    }
}

.app__skills-item {
    flex-direction: column;
    text-align: center;

    margin: 1rem;

    transition: all 0.3s ease-in-out;

    div {
        width: 90px;
        height: 90px;
        border-radius: 5px;
        background-color: rgb(79, 79, 79);
    }

    img {
        width: 50px;
        height: 50px;
    }

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 25px #41a24e;
    }

    @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
    }

    @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
    }

    p {
        font-weight: 500;
        margin-top: 0.5rem;
    }

    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;

        p {
            margin-top: 1rem;
        }
    }

}
.app__skills-exp{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;

    @media screen and (max-width: 900px) {
        margin-top: 2rem;
        
    }
}

.app__skills-exp-work{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin:1rem 0;
    cursor: pointer;
    
    h4{
        font-weight: 500;
    }
    p{
        font-weight: 500;
        color:var(--gray-color);
        margin-top: 5px;
        
        
    }
}
.app__skills-exp-year{
    margin-right: 3rem;
    font-weight: 800;
    color: var(--secondary-color);

    @media screen and (max-width: 450px) {
        margin-right: 1rem;
    }


}
.p-text-desc{
    font-weight: 400;
    color: rgb(112, 125, 196) !important;
}